import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi, HospitalGETAPi } from "../../Services/HospitalApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

export const addMedicationScheduleAction =
  (data, onRes) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalGETAPi(
      "post",
      HospitalConstants.END_POINT.ADD_MEDICATION_SCHEDULE,
      data
    );
    onRes(res);
    if (res.statusCode) {
      if (res.data) {
        dispatch({
          type: types.ADD_MEDICATION_SCHEDULE,
          payload: res.data,
        });
      }
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

export const getMedicationScheduleAction = (id, onRes) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalGETAPi(
    "get",
    HospitalConstants.END_POINT.ADD_MEDICATION_SCHEDULE + "/" + id
  );
  onRes(res);
  if (res.statusCode) {
    if (res.data) {
      dispatch({
        type: types.ADD_MEDICATION_SCHEDULE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const deleteMedicationScheduleAction =
  (data, onRes) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalGETAPi(
      "post",
      HospitalConstants.END_POINT.DELETE_MEDICATION_SCHEDULE,
      data
    );
    onRes(res);
    if (res.statusCode) {
      dispatch(hideLoaderAction());
      // dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(hideLoaderAction());
      // dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };
