import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientAction } from "../../../Redux/HospitalAction/clientAction";
import { useNavigate, useParams } from "react-router-dom";
import {
  addMedicationScheduleAction,
  deleteMedicationScheduleAction,
  getMedicationScheduleAction,
} from "../../../Redux/HospitalAction/medicationScheduleAction";
import { showToast } from "../../../Redux/HospitalAction/toastAction";
import { updateNestedObject } from "../../../utils/helpers";

const MedicationScheduleContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [deleteButton, setDeleteButton] = useState(false);
  const dosageOptions = [
    { name: "Hours", value: "hours" },
    { name: "Day", value: "day" },
  ];
  const durationOptions = [
    { name: "Day", value: "day" },
    { name: "months", value: "months" },
  ];
  const [TableData, setTableData] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const getClientData = useSelector(
    (state) => state.getClient.getClientProfile
  );

  const emptyObject = {
    client_id: "",
    name: "",
    dosage_amount: "",
    number_of_times: 0,
    send_reminders: false,
    start_date: "",
    end_date: "",
    time_between: {
      number: "",
      time: "",
    },
    duration: {
      number: "",
      time: "",
    },
  };

  const [data, setData] = useState(emptyObject);
  const [error, setError] = useState("");
  const [TimeFrames, setTimeFrames] = useState([]);
  const [timeFrameErrors, setTimeFrameErrors] = useState();

  useEffect(() => {
    if (getClientData.length) {
      let list = getClientData?.map((client) => {
        return {
          name: `${client?.profile_id} ${client?.name}`,
          value: client?.id,
        };
      });
      setClientsList(list);
    } else {
      dispatch(getClientAction());
    }
  }, [getClientData]);

  useEffect(() => {
    if (params?.id) {
      getMedicationInfo(params?.id);
    }
  }, [params]);

  const getMedicationInfo = (id) => {
    dispatch(
      getMedicationScheduleAction(id, (res) => {
        if (res?.statusCode) {
          let list = res?.data?.map((item, index) => {
            return {
              ...item,
              SrNo: index + 1,
              DoseNo: index + 1,
            };
          });
          setTableData(list);
        }
      })
    );
  };

  const TableHeading = [
    { field: "SrNo", header: "Sr. No." },
    { field: "name", header: "Medication Name " },
    { field: "dosage_amount", header: "Amount" },
    { field: "number_of_times", header: "No. of Time" },
    {
      field: "timeframe_for_dose",
      header: "Timeframe for dose ",
    },
    { field: "period", header: "Period" },

    { field: "DoseNo", header: "Dose No" },
    {
      field: "timeframe_for_additional_dose_one",
      header: "Timeframe for additional dose",
    },
    { field: "day", header: "Days" },
    { field: "start_date", header: "Start Date" },
    { field: "end_date", header: "End Date" },
    {
      field: "timeframe_for_additional_dose_two",
      header: "Timeframe for additional dose",
    },
    { field: "months", header: "Months" },
    { field: "icon", header: "Action", body: "addscheduleiconBodyTemplate" },
  ];

  const handleChange = (e) => {
    setData({
      ...data,
      [e?.target?.name]: e?.target?.value,
    });
    setError({
      ...error,
      [e?.target?.name]: "",
    });
  };

  const handleDropDownChange = (e) => {
    let name = e?.target?.name;
    let value = e?.value?.id || e?.value;
    setData({
      ...data,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });

    const res = updateNestedObject(data, e.target.name, e.value);

    setData({ ...res });
    setError({
      ...error,
      [e.target?.name]: "",
    });
  };

  const handleNumberChange = (e) => {
    const res = updateNestedObject(data, e.target.name, e.value);

    setData({ ...res });
    setError({
      ...error,
      [e.target?.name]: "",
    });
  };

  const showErrors = () => {
    if (
      data?.client_id === "" ||
      data.name === "" ||
      data.dosage_amount === "" ||
      data.number_of_times === 0 ||
      !data.time_between.number ||
      !data.time_between.time ||
      !data.duration.number ||
      !data.duration.time
    ) {
      setError({
        ...error,
        client_id:
          data.client_id === "" ? "Please Select a client" : error?.client_id,
        name: data.name === "" ? "Please Enter Medication Name" : error.name,

        dosage_amount:
          data.dosage_amount === ""
            ? "Please Enter Dosage dosage amount"
            : error.dosage_amount,

        number_of_times:
          data.number_of_times === 0
            ? "Please Enter Number of Time"
            : error.number_of_times,

        time_between: {
          number: data.duration.number ? "Required" : "",
          time: data.duration.time ? "Required" : "",
        },
      });
      return false;
    } else {
      return true;
    }
  };

  const showTimeFrameErrors = () => {
    let updateErrors = timeFrameErrors;
    let hasError = false;
    if (TimeFrames && TimeFrames.length) {
      let firstFrame = TimeFrames[0];
      if (firstFrame) {
        let keys = Object.keys(firstFrame);
        keys.forEach((key) => {
          if (!firstFrame[key]) {
            updateErrors = {
              ...updateErrors,
              [key]: `Please enter ${
                key === "start_date"
                  ? "start date"
                  : key === "end_date"
                  ? "end date"
                  : key
              }`,
            };
            hasError = false;
          } else {
            hasError = true;
          }
        });
      }
      setTimeFrameErrors(updateErrors);
    }
    return hasError;
    // dsf
  };

  const handleAddMedicineDetails = () => {
    let isFormError = showErrors();
    let isTimeFrameError = showTimeFrameErrors();
    if (isFormError && isTimeFrameError) {
      onsubmit();
    }
  };

  const handleUpdateMedicalDetails = () => {
    let isFormError = showErrors();
    let isTimeFrameError = showTimeFrameErrors();
    if (isFormError && isTimeFrameError) {
      onsubmit();
    }
  };

  const handleDateChange = (e) => {
    let date = new Date(e.target.value);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    let formattedDate = year + "/" + month + "/" + dt;
    setData({
      ...data,
      [e.target?.name]: formattedDate,
    });
    setError({
      ...error,
      [e.target?.name]: "",
    });
  };

  useEffect(() => {
    if (selectedId && !deleteButton) {
      onEdit();
      setIsEdit(true);
    }
  }, [deleteButton, selectedId]);

  const onEdit = () => {
    let allTimeFrames = [...TimeFrames];
    let colData = TableData.find((col) => col?.id === selectedId);
    if (colData?.timeframe_for_dose_data) {
      allTimeFrames.push({
        name: "timeframe_for_dose",
        value: colData?.timeframe_for_dose,
        ...colData?.timeframe_for_dose_data,
      });
    }

    if (colData?.timeframe_for_additional_dose_one_data) {
      allTimeFrames.push({
        name: "timeframe_for_additional_dose_one",
        value: colData?.timeframe_for_additional_dose_one,
        ...colData?.timeframe_for_additional_dose_one_data,
      });
    }

    if (colData?.timeframe_for_additional_dose_two_data) {
      allTimeFrames.push({
        name: "timeframe_for_additional_dose_two",
        value: colData?.timeframe_for_additional_dose_two,
        ...colData?.timeframe_for_additional_dose_two_data,
      });
    }

    setTimeFrames(allTimeFrames);
    setData(colData);
  };

  const handleDelete = () => {
    if (selectedId) {
      dispatch(
        deleteMedicationScheduleAction({ id: [selectedId] }, (res) => {
          if (res?.statusCode) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            let list = TableData.filter((col) => col.id !== selectedId);
            setTableData(list);
            setDeleteButton(false);
            setSelectedId("");
          } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
          }
        })
      );
    } else {
      let list = TableData?.map((item) => {
        return item?.id;
      });
      dispatch(
        deleteMedicationScheduleAction({ id: list }, (res) => {
          navigate("/account-owner/medication-schedule");
        })
      );
    }
  };

  const onsubmit = () => {
    let payload = {
      ...data,
    };

    dispatch(
      addMedicationScheduleAction(payload, (res) => {
        if (res?.statusCode) {
          let number = TableData.length + 1;
          let list = [...TableData];
          if (selectedId) {
            let indexToUpdate = list.findIndex((obj) => obj?.id === selectedId);
            if (indexToUpdate != -1) {
              let item = {
                ...res?.data,
                SrNo: indexToUpdate + 1,
                DoseNo: indexToUpdate + 1,
              };
              list[indexToUpdate] = item;
              setTableData(list);
              setIsEdit(false);
              setData(emptyObject);
              setTimeFrames([]);
            }
            setSelectedId("");
          } else {
            let item = {
              ...res?.data,
              SrNo: number,
              DoseNo: number,
            };
            list.push(item);
            setTableData(list);
            setData(emptyObject);
            setTimeFrames([]);
          }
        }
      })
    );
  };

  const handleCancel = () => {
    if (TableData?.length && !params?.id) {
      setDeleteButton(true);
    } else {
      navigate("/account-owner/medication-schedule");
    }
  };

  const handleTimeframeChange = (index, name, value) => {
    const allFrames = [...TimeFrames];
    let errors = timeFrameErrors;
    if (index > -1) {
      allFrames[index][name] = value;
      if (!value) {
        errors[name] = `Please enter ${name}`;
      } else {
        errors[name] = "";
      }
    }
    setError(errors);
    setTimeFrames(allFrames);
  };

  const handleTimeframeDateChange = (name, value) => {
    let date = new Date(value);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    let formattedDate = year + "/" + month + "/" + dt;

    setData({
      ...data,
      [name]: formattedDate,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  return {
    data,
    setData,
    handleDropDownChange,
    clientsList,
    error,
    dosageOptions,
    durationOptions,
    handleChange,
    handleNumberChange,
    handleDateChange,
    isEdit,
    handleUpdateMedicalDetails,
    handleAddMedicineDetails,
    TableHeading,
    TableData,
    setDeleteButton,
    deleteButton,
    selectedId,
    setSelectedId,
    handleDelete,
    handleCancel,
    navigate,
    TimeFrames,
    setTimeFrames,
    timeFrameErrors,
    setTimeFrameErrors,
    handleTimeframeChange,
    handleTimeframeDateChange,
  };
};

export default MedicationScheduleContainer;
