import React from "react";
import { Card } from "primereact/card";
import ContentArea from "../../../shared/ContentArea";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import { Dropdown } from "primereact/dropdown";
import InputField from "../../../components/input/input";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import Table from "../../../components/TableList/Table";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Deleteimg from "../../../assets/images/deleted.png";
import InputNumberField from "../../../components/input/inputNumber";
import MedicationScheduleContainer from "./MedicationScheduleContainer";

const MedicationScheduleForms = ({ title }) => {
  const {
    data,
    setData,
    handleDropDownChange,
    clientsList,
    error,
    dosageOptions,
    durationOptions,
    handleChange,
    handleNumberChange,
    handleDateChange,
    isEdit,
    handleUpdateMedicalDetails,
    handleAddMedicineDetails,
    TableHeading,
    TableData,
    setDeleteButton,
    deleteButton,
    selectedId,
    setSelectedId,
    handleDelete,
    handleCancel,
    navigate,
    TimeFrames,
    setTimeFrames,
    timeFrameErrors,
    setTimeFrameErrors,
    handleTimeframeChange,
    handleTimeframeDateChange,
  } = MedicationScheduleContainer();

  console.log("data", data);

  console.log("error", error)

  return (
    <>
      <ContentArea>
        <div className="add_schedule_container">
          <div className="p-3">
            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
              <h2 className="text-main">{title} a Medication Schedule</h2>
            </div>{" "}
            <Card className="shadow-2 px-3 border-round-lg mb-5">
              <div className="p-fluid grid mb-5 flex align-items-center ">
                <div className="col-12 md:col-12">
                  <h1
                    className="text-main font-bold "
                    style={{ fontSize: "22px" }}
                  >
                    Medicine Details
                  </h1>
                </div>
                <div className="col-12 md:col-4 ">
                  <label className="font block mb-2">Client</label>
                  <Dropdown
                    value={data?.client_id}
                    onChange={handleDropDownChange}
                    options={clientsList}
                    optionLabel="name"
                    placeholder="Select"
                    className="w-full border-round-md"
                    name="client_id"
                    filter
                    disabled={title === "Edit" ? true : false}
                  />
                  {error.client_id && (
                    <span className="error">{error.client_id}</span>
                  )}
                </div>
              </div>
            </Card>
            <Card className="shadow-2 p-3 border-round-lg mb-5">
              <div className="p-fluid grid mb-5 flex align-items-center ">
                <div className="col-12 md:col-12">
                  <h1
                    className="text-main font-bold "
                    style={{ fontSize: "22px" }}
                  >
                    Medicine Details
                  </h1>
                </div>

                <div className="flex w-full">
                  <div className="col-12 md:col-4">
                    <label className="font block ">Medication Name </label>
                    <InputField
                      name="name"
                      value={data?.name}
                      onChange={handleChange}
                    />
                    {error.name && <span className="error">{error.name}</span>}
                  </div>

                  <div className="col-12 md:col-4">
                    <label className="font block mb-2">
                      Enter Number of times/Day
                    </label>
                    <div className=" add_schedule_increment_button  border-round-md ">
                      <InputNumberField
                        inputClassName=" increment_button border-round-md w-full border-0"
                        className="w-full p-invalid"
                        inputId="minmax-buttons"
                        value={data?.number_of_times}
                        onValueChange={handleNumberChange}
                        mode="decimal"
                        showButtons
                        min={0}
                        name="number_of_times"
                      />
                    </div>
                    {error.number_of_times && (
                      <span className="error">{error.number_of_times}</span>
                    )}
                  </div>
                  <div className="col-12 md:col-2">
                    <label className="font block mb-2">Time b/w Dosage</label>
                    <div className=" add_schedule_increment_button  border-round-md ">
                      <InputNumberField
                        inputClassName=" increment_button border-round-md w-full border-0"
                        className="w-full p-invalid"
                        inputId="minmax-buttons"
                        value={data?.time_between?.number}
                        onValueChange={handleNumberChange}
                        mode="decimal"
                        showButtons
                        min={0}
                        name="time_between.number"
                        placeholder="Enter no"
                      />
                    </div>
                    {error.time_between?.number && (
                      <span className="error">{error.time_between?.number}</span>
                    )}
                  </div>
                  <div
                    className="col-12 md:col-2"
                    style={{ marginTop: "27px" }}
                  >
                    <Dropdown
                      // value={data?.timeframe_for_dose}
                      // onChange={(e) => setDuration(e.value)}
                      onChange={handleDropDownChange}
                      options={dosageOptions}
                      optionLabel="name"
                      placeholder="Select"
                      className="w-full border-round-md"
                      name="time_between.time"
                    />
                    {error.time_between?.time && (
                      <span className="error">{error.time_between?.time}</span>
                    )}
                  </div>
                </div>

                <div className="flex w-full">
                  <div className="col-12 md:col-2">
                    <label className="font block mb-2">Duration</label>
                    <div className=" add_schedule_increment_button  border-round-md ">
                      <InputNumberField
                        inputClassName=" increment_button border-round-md w-full border-0"
                        className="w-full p-invalid"
                        inputId="minmax-buttons"
                        value={data?.number_of_times}
                        onValueChange={handleNumberChange}
                        mode="decimal"
                        showButtons
                        min={0}
                        name="duration.number"
                        placeholder="Enter no"
                        useGrouping={false}
                      />
                    </div>
                    {error.timeframe_for_dose && (
                      <span className="error">{error["duration.number"]}</span>
                    )}
                  </div>

                  <div className="col-12 md:col-2" style={{ marginTop: "27px" }}>
                    <Dropdown
                      // value={data?.timeframe_for_dose}
                      // onChange={(e) => setDuration(e.value)}
                      onChange={handleDropDownChange}
                      options={durationOptions}
                      optionLabel="name"
                      placeholder="Select"
                      className="w-full border-round-md"
                      name="duration.time"
                    />
                    {error.timeframe_for_dose && (
                      <span className="error">{error["duration.time"]}</span>
                    )}
                  </div>

                  <div className="col-12 md:col-4">
                    <label className="font block mb-2">Start Date</label>
                    <Calendar
                      // minDate={new Date()}
                      className="calender_picker"
                      inputClassName="border-round-md"
                      name="start_date"
                      // value={new Date(date?.start_date)}
                      onChange={(e) => { handleTimeframeDateChange("start_date", e?.target?.value) }}
                    />
                    {error?.start_date && (
                      <span className="error">{error?.start_date}</span>
                    )}
                  </div>

                  <div className="col-12 md:col-4">
                    <label className="font block mb-2">Start End</label>
                    <Calendar
                      // minDate={new Date(frame?.start_date)}
                      className="calender_picker"
                      inputClassName="border-round-md"
                      name="end_date"
                      // value={new Date(frame?.end_date)}
                      onChange={(e) => { handleTimeframeDateChange("end_date", e?.target?.value) }}
                    />
                    {error?.end_date && (
                      <span className="error">{error?.end_date}</span>
                    )}
                  </div>
                </div>

                <div className="flex w-full">
                  <div className="col-12 md:col-4">
                    <label className="font block ">Enter Dosage</label>
                    <InputField
                      name="dosage_amount"
                      value={data?.dosage_amount}
                      onChange={handleChange}
                    />
                    {/* <div className="p-inputgroup flex-1">
                      <InputText
                        name="dosage_amount"
                        value={data?.dosage_amount}
                        onChange={handleChange}
                      />
                      <span className="p-inputgroup-addon">ML</span>
                    </div> */}
                    {error.dosage_amount && (
                      <span className="error">{error.dosage_amount}</span>
                    )}
                  </div>
                </div>

                <div className="col-12 md:col-12 mb-3">
                  <p className="text-color_rgb">
                    <span className="text-500">
                      <Checkbox
                        onChange={(e) =>
                          setData({ ...data, send_reminders: e.checked })
                        }
                        checked={!!data?.send_reminders}
                        name="send_reminders"
                      ></Checkbox>{" "}
                      &nbsp;Send Reminders
                    </span>
                  </p>
                </div>
              </div>

              <div className=" btn flex justify-content-end">
                <div className="submitButton flex">
                  <SaveButton
                    label={isEdit ? "Update" : "Add"}
                    onClick={() => {
                      isEdit
                        ? handleUpdateMedicalDetails()
                        : handleAddMedicineDetails();
                    }}
                  ></SaveButton>
                  <CancelButton label="Cancel"></CancelButton>
                </div>
              </div>
            </Card>
            <Card className="shadow-2 p-3 border-round-lg">
              <div className="grid mt-2 mb-5">
                <div className=" add_schedule_table col-12 md:col-12">
                  <Table
                    className="add_schedule_table  "
                    tableHeading={TableHeading}
                    tableData={TableData}
                    setDeleteButton={setDeleteButton}
                    deleteButton={deleteButton}
                    setData={setSelectedId}
                  />
                </div>
              </div>
              {deleteButton ? (
                <Dialog
                  className="table_delete_modal flex justify-content-center "
                  visible={deleteButton}
                  onHide={() => setDeleteButton(false)}
                  style={{ width: "30vw" }}
                >
                  <p className="m-0">
                    <div className=" flex justify-content-center">
                      <img
                        src={Deleteimg}
                        alt=""
                        width={150}
                        height={127}
                      ></img>
                    </div>
                    <div className="text-center mb-5">
                      <h4 className=" p-0 m-0 ">
                        {selectedId
                          ? "Are you sure you want to delete this medication schedule?"
                          : "Are you sure you want to cancel this medication schedule?"}
                      </h4>
                      <p className="text-xs text-400">
                        All associated data will also be deleted! There is no
                        undo!
                      </p>
                    </div>
                    <div className=" btn flex justify-content-center">
                      <div className="text-center">
                        <Button
                          label="Cancel"
                          className="w-full surface-500 p-3 px-5 border-0 border-round-md "
                          onClick={() => setDeleteButton(false)}
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          label="Delete"
                          className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                          onClick={handleDelete}
                        />
                      </div>
                    </div>
                  </p>
                </Dialog>
              ) : null}

              <div className=" btn flex justify-content-end">
                <div className="submitButton flex">
                  <SaveButton
                    label="Save"
                    disabled={!TableData.length}
                    onClick={() =>
                      navigate("/account-owner/medication-schedule")
                    }
                  ></SaveButton>
                  <CancelButton
                    label="Cancel"
                    onClick={handleCancel}
                  ></CancelButton>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </ContentArea>
    </>
  );
};

export default MedicationScheduleForms;
