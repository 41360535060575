import React, { useEffect, useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import { Card } from "primereact/card";
import Table from "../../../components/TableList/Table";
import { useNavigate } from "react-router-dom";
import Index from "../Index";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Deleteimg from "../../../assets/images/deleted.png";
import SearchIcon from "../../../assets/icons/search.png";
import AddSchedule from "./AddSchedule";
import { HospitalApi } from "../../../Services/HospitalApi";
import HospitalConstants from "../../../Services/HospitalConstants";
import { useDispatch } from "react-redux";
import { deleteMedicationScheduleAction } from "../../../Redux/HospitalAction/medicationScheduleAction";
import { showToast } from "../../../Redux/HospitalAction/toastAction";

const MedicationSchedule = () => {
  const dispatch = useDispatch();
  const [deleteButton, setDeleteButton] = useState(false);
  const [data, setData] = useState([]);
  const { isNextPage, setIsNextPage } = Index();
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState("");

  const AddSchedule = () => {
    setIsNextPage(!isNextPage);
    navigate("/account-owner/add-schedule");
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await HospitalApi(
        "get",
        HospitalConstants.END_POINT.MEDICATION_SCHEDULES
      );

      let list = res?.data?.data?.map((schedule, index) => {
        return {
          srNo: index + 1,
          ...schedule,
        };
      });
      setData(list);
    } catch (error) {
      console.log(error);
    }
  };

  // const TableData = [
  //   {
  //     SrNo: 1,
  //     ClientName: "1234",
  //     ClientSchedule: "Oliver",
  //   },

  //   {
  //     SrNo: 2,
  //     ClientName: "1234",
  //     ClientSchedule: "Oliver",
  //   },
  //   {
  //     SrNo: 3,
  //     ClientName: "1234",
  //     ClientSchedule: "Oliver",
  //   },
  //   {
  //     SrNo: 4,
  //     ClientName: "1234",
  //     ClientSchedule: "Oliver",
  //   },

  //   {
  //     SrNo: 5,
  //     ClientName: "1234",
  //     ClientSchedule: "Oliver",
  //   },
  //   {
  //     SrNo: 6,
  //     ClientName: "1234",
  //     ClientSchedule: "Oliver",
  //   },
  // ];
  const TableHeading = [
    { field: "srNo", header: "Sr. No." },
    { field: "id", header: "Client Id" },
    { field: "name", header: "Client Name" },
    { field: "", header: "Action", body: "medicationiconBodyTemplate" },
  ];

  const handleDelete = () => {
    let itemInfo = data.find((item) => item.id === selectedId);
    dispatch(
      deleteMedicationScheduleAction({ id: itemInfo?.Ids }, (res) => {
        if (res?.statusCode) {
          dispatch(showToast({ severity: "success", summary: res.message }));
          setDeleteButton(false);
          getData();
        } else {
          dispatch(showToast({ severity: "error", summary: res.message }));
        }
      })
    );
  };

  return (
    <>
      <ContentArea>
        <div className="add_manage_service_container">
          <div className="p-3">
            <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
              <div className="md:col-6">
                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                  Manage Medication Schedules
                </h1>
              </div>
              <div className="md:col-3">
                <div className="search-box w-full">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    width="15px"
                    height="15px"
                  />
                  <input type="search" placeholder="Search" />
                </div>
              </div>

              <div className="md:col-3 w-max ">
                <button
                  className="p-0 px-5 bg-main border-round-md"
                  onClick={() => AddSchedule()}
                >
                  <p
                    className="p-0 text-white font-medium"
                    style={{ fontSize: "14px" }}
                  >
                    Add a Schedule +
                  </p>
                </button>
              </div>
            </div>

            <Card className="shadow-2 p-3 border-round-lg">
              <div className="today mb-5">
                <h2
                  className="text-main font-bold "
                  style={{ fontSize: "22px" }}
                >
                  Client List
                </h2>
                <Table
                  tableHeading={TableHeading}
                  tableData={data}
                  setDeleteButton={setDeleteButton}
                  setData={setSelectedId}
                />
              </div>
              {deleteButton ? (
                <Dialog
                  className="table_delete_modal flex justify-content-center "
                  visible={deleteButton}
                  onHide={() => setDeleteButton(false)}
                  style={{ width: "30vw" }}
                >
                  <p className="m-0">
                    <div className=" flex justify-content-center">
                      <img
                        src={Deleteimg}
                        alt=""
                        width={150}
                        height={127}
                      ></img>
                    </div>
                    <div className="text-center mb-5">
                      <h4 className=" p-0 m-0 ">
                        Are you sure you want to delete this medication
                        schedule?
                      </h4>
                      <p className="text-xs text-400">
                        All associated data will also be deleted! There is no
                        undo!
                      </p>
                    </div>
                    <div className=" btn flex justify-content-center">
                      <div className="text-center">
                        <Button
                          label="Cancel"
                          className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                          onClick={() => setDeleteButton(false)}
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          label="Delete"
                          className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                          onClick={handleDelete}
                        />
                      </div>
                    </div>
                  </p>
                </Dialog>
              ) : null}
            </Card>
          </div>
        </div>
      </ContentArea>
    </>
  );
};

export default MedicationSchedule;
